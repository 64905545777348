@import "../../../styles/variable";

.header {
  margin:24px 24px 0;
  //(-1 * (100vw - 100%)) - для того чтобы хедер не дёргался при открытии модалки
  width: calc(100% - (-1 * (100vw - 100%)) - 48px);
  background: $color-bg-header;
  padding: 12px 16px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 402;
  overflow: hidden;
  height: 66px;
  transition: height .5s, background .5s;
  top: 0;

  .top {
    display: flex;
    align-items: center;
  }
  .button {
    cursor: pointer;
    width: 24px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 37px;
    span {
      display: block;
      width: 100%;
      height: 2px;
      background: white;
      transform: none;
      transition: transform .5s;
    }

  }
  .item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    &:first-of-type {
      justify-content: flex-start;
    }

  }
  .right {
    justify-content: flex-end;
  }
  .phone {
    color: white;
    margin: 0 21px 0 12px;
    padding: 12px 50px 12px 0;
    white-space: nowrap;
    background-image: url("/files/images/svg/header-phone.svg");
    background-repeat: no-repeat;
    background-position: right center;
    &:hover {
      color: $color-yellow-light;
    }
  }
  .link {
    color: #fff;
    font-size: 14px;
    margin: 0 16px;
    transition: .5s;
    &.bordered {
      border: 0.5px solid $color-yellow-light;
      padding: 10px;
      color: $color-yellow-light;
      &:hover {
        color: #fff;
        border-color: #fff;
      }
    }
    &:hover {
      color: $color-yellow-light;
    }
  }
  .logo {
    width: 298px;
    height: 32px;
    background: url("/files/images/svg/logo.svg") left center/ auto 100% no-repeat;
    font-size: 0;
    margin-right: 20px;
  }
  .granelle {
    background-image: url("/files/images/svg/logo-granelle.svg");
    margin: 0 17px 0 12px;
    width: 44px;
    height: 26px;
  }
  .fin {
    background-image: url("/files/images/svg/constr-fin-logo.svg");
    margin: 0 17px 0 12px;
    width: 139px;
    height: 32px;
  }
  .developer {
    font-size: 0;
    line-height: 0;

  }
}

//active

.open {
  height: calc(100vh - 48px);
  background: rgba(15, 22, 41, 0.96);
}

.hide {
  display: none;
}

.active {
  span{
    position: relative;
    &:nth-of-type(1) {
      top: 7px;
      transform: rotate(45deg);
    }
    &:nth-of-type(2) {
      display: none;
    }
    &:nth-of-type(3) {
      transform: rotate(-45deg);
      bottom: 7px;
    }
  }
}

.favorites {
  position: relative;
  cursor: pointer;

  .count {
    position: absolute;
    width: 11px;
    height: 13px;
    left: 32px;
    top: -6px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

    opacity: 0.5;
  }
}

.line {
  width: 35px;
  margin-right: -5px;
  opacity: 0.2;
  border: 1px solid #FFFFFF;
  transform: rotate(90deg);
}

@media all and (max-width: 1439px) {
  .header {
    .link {
      margin: 0 10px;
      font-size: 12px;
    }
  }
}

@media all and (max-width: 1168px) {
  .header {
    .item {
      &:not(.right) {
        .link {
          display: none;
        }
      }
    }
  }
}

@media all and (max-width: 940px) {
  .header {
    .phone {
      margin-right: 23px;
    }
  }
  .open {
    overflow-y: scroll;
  }
}

@media all and (max-width: 700px) {
  .favorites {
    display: none;
  }
  .line {
    display: none;
  }

  .header {
    margin: 0;
    width: 100%;
    .item {
      &:first-of-type {
        justify-content: space-between;
      }
    }
    .developer {
      display: none;
    }

    .phone {
      font-size: 0;
      height: 40px;
      margin: 0;
      padding-left: 50px;
      background-image: url("/files/images/svg/header-phone.svg");
    }
  }
  .open {
    height: 100vh;
  }
}
