// COLOR
$color-bg-header: rgba(15, 28, 44, 0.9);
$color-blue-dark: #1E324B;
$color-blue-darker: #2C3F56;
$color-blue-lighter: #ABC3D5;
$color-blue-lighter-progress: #60a5e8;
$color-blue-lightest: #F2F6F9;
$color-gray: rgba(255, 255, 255, 0.5);
$color-yellow-light: #DED1A2;
$color-border: #536589;
$color-bg-aside: #F3F3F6;
$color-gray-light: #C5C7CE;
$color-gray-lighter: #DDE7EE;
$color-red: #f15642;
$color-yellow: #DCD8AB;

//  Шрифты
$font-mont: 'Montserrat',  Arial, sans-serif;
$font-geo: 'Geometria', Arial, sans-serif;

// Наследование
//  @extend %center;
%center {
  max-width: 926px;
  margin: 0 auto;
  //padding: 0 20px;

  //@media all and (max-width: 489px){
  //  padding: 0 10px;
  //}
}

//SCROLL
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%), $border-color: transparent) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border: 1px solid $border-color;
    border-radius: $size;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}


