.section {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 80px;
  position: relative;
}

.rouble {
  position: relative;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: normal;
}

@media all and (max-width: 1439px) {
  .section {
    max-width: 1168px;
  }
}

@media all and (max-width: 1168px) {
  .section {
    padding: 0px 40px;
  }
}

@media all and (max-width: 1000px){
  .rouble {
    &::after {
      top: 6px;
    }
    &.fs30::after {
      top: 2px;
    }
  }
}

@media all and (max-width: 700px) {
  .section {
    padding: 0px 20px;
  }
  .rouble.fs30::after {
      left: 0;
  }
}
