@import "../../../styles/variable";

.footer {
  font-family: $font-geo;
  min-height: 424px;
  padding: 34px 0 60px;
  background-color: $color-blue-dark;
  flex-grow: 1;
  .content {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 96px;
    .logo {
      width: 200px;
      height: 50px;
      background: url("/files/images/svg/logo.svg") center no-repeat;
      background-size: 200px 50px;
      font-size: 0;
      margin-right: 20px;
    }
  }
  .phone {
    font-size: 24px;
    color: white;
    margin-bottom: 24px;
    display: block;
    line-height: 1.4;
    &:hover {
      color: $color-yellow-light;
    }
  }
  .address {
    max-width: 230px;
    font-size: 14px;
    color: white;
    margin-bottom: 64px;
  }
  .cell {
    p {
      font-size: 14px;
      line-height: 171%;
      margin-bottom: 15px;
      color: $color-gray;
      max-width: 573px;
    }
  }
  .links {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .link {
    color: #fff;
    margin-right: 18px;
    margin-bottom: 8px;
    white-space: nowrap;
    &:hover {
      color: $color-yellow-light;
    }
  }
  .granelle {
    font-size: 0;
    width: 118px; // 194px;
    height: 34px;
    background: url("/files/images/svg/logo-granelle_2.svg") center no-repeat;
    background-size: 100%;
    margin-bottom: 24px;
    display: block;
  }
  .develop {
    align-self: flex-end;
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    color: $color-gray;
    a{
      line-height: 0;
    }
  }
  .ddp {
    font-size: 0;
    background: url("/files/images/svg/ddp-logo.svg") center no-repeat;
    background-size: 62px 11px;
    width: 62px;
    height: 11px;
    display: inline-block;
    margin-left: 10px;
  }
}

@media all and (max-width: 1168px) {
  .footer {
    padding: 34px 40px 60px;
  }
}

@media all and (max-width: 940px) {
  .footer {
    .item {
      flex-wrap: wrap;
      margin-bottom: 56px;
    }
    .logo {
      margin-bottom: 60px;
    }
    .contacts {
      width: 100%;
      justify-content: space-between;
    }
    .cell {
      margin-bottom: 57px;
    }
    .develop {
      width: 100%;
    }
  }
}

@media all and (max-width: 700px) {
  .footer {
    padding: 34px 20px 60px;
    .logo {
      margin-bottom: 40px;
    }
    .item {
      width: 100%;
      &:nth-last-of-type(1) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .item,
    .contacts {
      flex-direction: column;
    }
    .phone {
      margin-bottom: 23px;
    }
    .links {
      display: flex;
      flex-wrap: wrap;
    }
    .link {
      margin-bottom: 10px;
      margin-right: 0;
      width: 100%;
    }
    .cell {
      margin-bottom: 33px;
    }
  }
}
